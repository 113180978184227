<template>
  <PageContainer>
    <ClientOnly>
      <VoucherShop />
    </ClientOnly>
  </PageContainer>
</template>

<script setup lang="ts">
const { t } = useI18n()

// useLegacyWidget({
//   iframeName: 'kinoheld-widget',
//   cinemaPath: '/vouchers',
//   floatingCart: false,
//   hideSearch: true,
// })

const { ct, fetchCinemaStrings } = useCinemaStrings()
await fetchCinemaStrings({
  keys: [VOUCHER_STRINGS.SHOP_INTRO, VOUCHER_STRINGS.SHOP_HEADLINE],
})

usePageMetas({
  title: t('vouchers'),
  description: t('description'),
})

defineI18nRoute({
  paths: {
    en: '/vouchers',
    de: '/gutscheine',
    es: '/codigos',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageVouchers',
})
</script>

<i18n>
de:
  vouchers: "Gutscheine"
  description: "Jetzt Kino Gutscheine schnell und bequem online kaufen."
es:
  vouchers: "Códigos"
  description: "Compra ahora códigos de cine de forma rápida y sencilla."
</i18n>
